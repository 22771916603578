import PropTypes from 'prop-types';
import { Label } from 'semantic-ui-react';
import styled from 'styled-components';

import HelpTooltip from 'components/ui/HelpTooltip';

import * as svars from 'assets/style/variables';
import {
  accentColor,
  accentColorDisabled,
  colorPrimary,
  disabledOpacity,
  fontColorBase,
  fontSizeBase,
  fontWeightLight,
} from 'assets/style/variables';

export default styled.label`
  font-weight: ${fontWeightLight};
  color: ${fontColorBase};
`;

export const PrimaryLabel = styled.label`
  &&&&& {
    background-color: transparent;
    color: ${colorPrimary};
  }
`;

const TransparentLabelContainer = styled(Label)`
  &&& {
    background-color: transparent;
    opacity: ${(props) => (props.disabled ? disabledOpacity : 1)};
    color: ${(props) => (props.disabled ? accentColorDisabled : accentColor)};

    & * {
      color: ${(props) => (props.disabled ? accentColorDisabled : accentColor)};
    }
  }
`;

export function TransparentLabel({ label, disabled, help }) {
  return (
    <TransparentLabelContainer disabled={disabled}>
      <span style={{ fontSize: fontSizeBase, paddingRight: '0.7rem' }}>
        {label}
      </span>
      {help ? <HelpTooltip size="small" help={help} /> : null}
    </TransparentLabelContainer>
  );
}
TransparentLabel.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  help: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

TransparentLabel.defaultProps = {
  label: undefined,
  disabled: false,
  help: undefined,
};
export const WarningLabel = styled(Label)`
  &&&&& {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${svars.fontColorBase} !important;
    font-weight: ${svars.fontWeightMedium};
    min-width: ${svars.smallButtonMinWidth};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & .icon {
      font-size: ${svars.fontSizeMedium};
      line-height: 1.1;
    }
    ${svars.hoverClickableCss}
  }
`;
