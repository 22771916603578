import React from 'react';

import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';
import sum from 'lodash/sum';
import PropTypes from 'prop-types';
import { Accordion, Icon, List } from 'semantic-ui-react';

import { Checkbox } from 'components/ui/inputs/Checkbox';

import * as styleVariables from 'assets/style/variables';

import BaseCheckboxList, { baseListItemProps } from './BaseCheckboxList';

/**
 * Add remaining nodes from `itemChildren` map to baseItems in a hierarchical manner, recursively.
 *
 * @param {Object} itemChildren A mapping of parent key to array of children keys.
 * @param {Object} itemMap A mapping of item key to item.
 * @param {Array<Object>} baseItems A list of items.
 * @param {Object} addNonRooted Whether to add nodes from itemChildren even if their parent is not present in `baseItems`.
 */
export const addNested = (itemMap, itemChildren) => {
  const addNestedNodes = (baseItems, addNonRooted = true) => {
    // Rooted items are the ones that have a root ancestor (i.e. an ancestor with no parent)
    const rootedItems = baseItems.map((item) => {
      const childrenKeys = itemChildren[item.key];
      if (childrenKeys) {
        delete itemChildren[item.key];
        return {
          ...item,
          children: addNestedNodes(
            childrenKeys.map((itemKey) => itemMap[itemKey]),
            false
          ),
        };
      }
      return item;
    });

    let items;
    if (addNonRooted) {
      // Add non rooted items
      const nonRootedItemsMap = {};
      Object.entries(itemChildren).forEach(([parentKey, childrenKeys]) => {
        const parentItem = itemMap[parentKey];
        const children = addNestedNodes(
          childrenKeys.map((itemKey) => {
            // If already added in non rooted items (possible depending on item order), pop it and use it as child item here
            const childItem = nonRootedItemsMap[itemKey];
            if (childItem) {
              delete nonRootedItemsMap[itemKey];
              return childItem;
            }
            // If not, return simple item (with no children field) from item map
            return itemMap[itemKey];
          }),
          false
        );
        if (parentItem) {
          // If parent is also present, use it to nest the children
          nonRootedItemsMap[parentKey] = {
            ...itemMap[parentKey],
            children,
          };
        } else {
          // If parent is absent, show the children as roots
          children.forEach((child) => {
            nonRootedItemsMap[child.key] = child;
          });
        }
      });
      items = [...rootedItems, ...Object.values(nonRootedItemsMap)];
    } else {
      items = rootedItems;
    }
    return sortBy(items, ['label']);
  };
  return addNestedNodes;
};

class BaseHierarchicalCheckboxList extends BaseCheckboxList {
  static isParent = (item) => item.children && item.children.length;

  constructor(props) {
    super(props);
    this.state = {
      textFilterValue: '',
      filteredItems: props.items,
      activeKeys: [],
    };
    this.renderItems = this.renderItems.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
    this.tabSize = 11;
  }

  /**
   * Add item if leaf or all its descendant leaves if parent.
   * This is used to update `selectedItems` both when adding or removing nodes.
   *
   * @memberof BaseHierarchicalCheckboxList
   */
  addItemsOrChildren = (toSelect, item, filterItems = null) => {
    if (item.children) {
      item.children.map((childItem) =>
        this.addItemsOrChildren(toSelect, childItem, filterItems)
      );
    } else if (!filterItems || !this.props.isSelectedItem(item, filterItems)) {
      toSelect.push(item);
    }
  };

  onSelectAllFilteredItems = () => {
    const toAdd = [];
    this.nestItems(this.state.filteredItems).forEach((item) =>
      this.addItemsOrChildren(toAdd, item, this.props.selectedItems)
    );
    this.props.onSelectItems(toAdd);
  };

  onItemClick = (item, isActive, isSelected) => (e) => {
    e.preventDefault();
    const { onSelectItems, onUnselectItems, selectedItems } = this.props;
    // To re-add as node additions ? e.target.tagName === 'LABEL' ||
    if (!BaseHierarchicalCheckboxList.isParent(item)) {
      // Node is a leaf, add item alone
      if (isSelected) {
        onUnselectItems([item]);
      } else {
        onSelectItems([item]);
      }
    } else if (e.target.tagName === 'I' && e.target.id === 'add') {
      // Icon add was clicked, adding node and descendants
      const toAdd = [];
      this.addItemsOrChildren(toAdd, item, selectedItems);
      onSelectItems(toAdd);
    } else if (e.target.tagName === 'I' && e.target.id === 'remove') {
      const toRemove = [];
      this.addItemsOrChildren(toRemove, item);
      onUnselectItems(toRemove);
    } else {
      // The dropdown icon of surrounding div is clicked, we activate the accordion element
      this.setState({
        activeKeys: isActive
          ? this.state.activeKeys.filter((activeKey) => activeKey !== item.key)
          : [...this.state.activeKeys, item.key],
      });
    }
  };

  nestItems = (items) => {
    const { getItemParent } = this.props;
    const itemChildren = {};
    const itemMap = {};
    const nestedItems = [];
    items.forEach((item) => {
      const parent = getItemParent(item);
      if (parent) {
        itemChildren[parent] = itemChildren[parent]
          ? [...itemChildren[parent], item.key]
          : [item.key];
      } else {
        nestedItems.push(item);
      }
      itemMap[item.key] = item;
    });

    return addNested(itemMap, itemChildren)(nestedItems);
  };

  countLeafItems = (item) =>
    !item.children ? 1 : sum(item.children.map(this.countLeafItems));

  renderNestedCheckboxLabel = (item) => {
    const { label } = item;
    const nChildren = this.countLeafItems(item);
    return (
      <span
        key={`nl-${item.key}`}
        style={{
          color: styleVariables.fontColorBase,
          fontWeight: styleVariables.fontWeightMedium,
        }}
      >
        {label}
        <span
          style={{
            color: styleVariables.fontColorLighter,
            fontStyle: 'italic',
          }}
        >
          {` (${nChildren} élément(s))`}
        </span>
      </span>
    );
  };

  renderParentNode = (item, isItemActive, isItemSelected, marginLeft) => (
    <Accordion
      style={{
        background: 'inherit',
        padding: 0,
        margin: 0,
        boxShadow: 'none',
      }}
    >
      <Accordion.Title
        index={0}
        active={isItemActive}
        style={{ color: styleVariables.accentColor, padding: 0 }}
        onClick={this.onItemClick(item, isItemActive, isItemSelected)}
        data-testid="bo-select-element-accordion-menu"
      >
        <Icon name="dropdown" />
        {isItemSelected ? (
          <Icon
            id="remove"
            name="minus square outline"
            style={{
              color: styleVariables.accentColor,
              fontSize: styleVariables.fontSizeLarge,
            }}
          />
        ) : (
          <Icon
            id="add"
            name="plus square outline"
            style={{
              color: styleVariables.fontColorBase,
              fontSize: styleVariables.fontSizeLarge,
            }}
          />
        )}
        <span>{this.renderNestedCheckboxLabel(item)}</span>
      </Accordion.Title>
      <Accordion.Content active={isItemActive} style={{ padding: 0 }}>
        {this.renderNestedItems(item.children || [], marginLeft + this.tabSize)}
      </Accordion.Content>
    </Accordion>
  );

  isSelectedParent = (nestedItem) => {
    const { isSelectedItem, selectedItems } = this.props;
    return nestedItem.children.some((nestedChild) =>
      nestedChild.children
        ? this.isSelectedParent(nestedChild)
        : isSelectedItem(nestedChild, selectedItems)
    );
  };

  renderNestedItems(items, marginLeft = 0) {
    const { selectedItems, isSelectedItem } = this.props;
    const { activeKeys } = this.state;
    return (
      <List selection verticalAlign="middle" style={{ height: '100%' }}>
        {items.map((item) => {
          let content = null;
          if (BaseHierarchicalCheckboxList.isParent(item)) {
            const isItemActive = activeKeys.includes(item.key);
            content = this.renderParentNode(
              item,
              isItemActive,
              this.isSelectedParent(item),
              marginLeft
            );
          } else {
            const isItemSelected = isSelectedItem(item, selectedItems);
            content = (
              <Checkbox
                checked={isItemSelected}
                onClick={this.onItemClick(item, false, isItemSelected)}
                label={item.label}
                data-testid="bo-select-element-check-button"
              />
            );
          }
          return (
            <List.Item
              style={{
                marginLeft: `${(marginLeft && this.tabSize) || 0}px`,
                padding: styleVariables.spaceNormal,
              }}
              key={`lii-${item.key}`}
            >
              {content}
            </List.Item>
          );
        })}
      </List>
    );
  }

  renderItems(items, marginLeft = 0) {
    const nestedItems = this.nestItems(items);
    return this.renderNestedItems(nestedItems, marginLeft);
  }
}

BaseHierarchicalCheckboxList.propTypes = {
  ...omit(BaseCheckboxList.propTypes, ['onSelectItem', 'onUnselectItem']),
  onSelectItems: PropTypes.func.isRequired,
  onUnselectItems: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      ...baseListItemProps,
      parent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ).isRequired,
  getItemParent: PropTypes.func,
};

BaseHierarchicalCheckboxList.defaultProps = {
  ...BaseCheckboxList.defaultProps,
  getItemParent: (item) => item.parent,
  isSelectedItem: (item, selectedItems) =>
    selectedItems.some(({ id }) => id === item.key),
};

export default React.memo(BaseHierarchicalCheckboxList);
