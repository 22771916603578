import { useState } from 'react';

import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';
import styled from 'styled-components';

import * as svars from 'assets/style/variables';

import BeatingLoader from '../BeatingLoader';
import { HoverableIconButtonWithTooltip } from '../icon/HoverableIcon';

const MAX_FILE_SIZE = 200 * 1024; // 200 KB
const ALLOWED_FILE_TYPES = ['image/png', 'image/jpeg', 'image/avif'];

const DroppableSegment = styled.div`
  display: flex;
  cursor: pointer;
  text-align: left;
  align-items: center;
  border: 2px dashed #ccc;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px;
  justify-content: space-between;
  background-color: ${svars.colorLighterGrey};
  transition: background-color 0.3s;
  min-width: 200px;
  &:hover {
    background-color: ${svars.colorLightGrey};
  }
`;

const ImageStyled = styled(Image)`
  &&&& {
    max-height: ${svars.logoPreviewMaxWidth};
    min-height: ${svars.logoPreviewMaxWidth};
    max-width: 400px;
    width: auto;
    height: auto;
  }
`;

const ErrorMessageWrapper = styled.div`
  display: flex;
  word-wrap: break-word;
  margin-top: ${svars.spaceNormalLarge};
  color: ${svars.colorDanger};
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const validateFile = (file) => {
  const fileName =
    file?.name.length > 20 ? `${file.name.slice(0, 20)}...` : file?.name;
  if (!ALLOWED_FILE_TYPES.includes(file?.type)) {
    return t`${fileName} n'a pas un format valide. Veuillez sélectionner des images en .png, .jpeg ou .avif`;
  }
  if (file.size > MAX_FILE_SIZE) {
    return t`${fileName} dépasse la limite de taille de 200 KB`;
  }
  return null;
};

const handleFiles = (
  fileList,
  setError,
  setUploadedFile,
  handleSelectedFileChangeProp
) => {
  const newFile = fileList[0];
  const err = validateFile(newFile);
  if (err) {
    setError(err);
  } else {
    setError(null);
    setUploadedFile(URL.createObjectURL(newFile));
    if (handleSelectedFileChangeProp) {
      handleSelectedFileChangeProp(newFile);
    }
  }
};

function ImageUpload({
  uploadedFile,
  previousFile,
  handleUpdateLogoUrl,
  handleSelectedFileChange: handleSelectedFileChangeProp,
}) {
  const [error, setError] = useState(null);

  const handleDrop = (e) => {
    e.preventDefault();
    handleFiles(
      e.dataTransfer.files,
      setError,
      handleUpdateLogoUrl,
      handleSelectedFileChangeProp
    );
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileSelect = (e) => {
    const { files } = e.target;
    if (files.length === 0) {
      return;
    }
    handleFiles(
      files,
      setError,
      handleUpdateLogoUrl,
      handleSelectedFileChangeProp,
      uploadedFile
    );
  };

  const getFilePreview = (file) => {
    if (file instanceof File) {
      return URL.createObjectURL(file);
    }
    return file;
  };

  const handleUndo = (e) => {
    e.stopPropagation();
    handleUpdateLogoUrl(previousFile);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
      }}
    >
      <DroppableSegment
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onClick={() => document.getElementById('fileInput').click()}
      >
        <ImageContainer>
          {' '}
          {uploadedFile === null ? (
            <BeatingLoader absolute />
          ) : (
            <ImageStyled src={getFilePreview(uploadedFile)} size="tiny" />
          )}
        </ImageContainer>

        <input
          id="fileInput"
          type="file"
          style={{ display: 'none' }}
          onChange={handleFileSelect}
          accept={ALLOWED_FILE_TYPES.join(',')}
        />
        <HoverableIconButtonWithTooltip
          name="undo"
          help={t`reset`}
          style={{ marginLeft: svars.spaceNormalLarge }}
          size="large"
          danger
          onClick={handleUndo}
          disabled={!previousFile || previousFile === uploadedFile}
        />
      </DroppableSegment>
      {error ? <ErrorMessageWrapper>{error}</ErrorMessageWrapper> : null}
    </div>
  );
}

ImageUpload.propTypes = {
  handleUpdateLogoUrl: PropTypes.func.isRequired,
  uploadedFile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(File),
  ]),
  previousFile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(File),
  ]),
  handleSelectedFileChange: PropTypes.func,
};
ImageUpload.defaultProps = {
  uploadedFile: null,
  previousFile: null,
  handleSelectedFileChange: null,
};

export default ImageUpload;
