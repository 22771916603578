

import PropTypes from 'prop-types';
import styled from 'styled-components';

import DashboardElement from './DashboardElement';

const computeRelativeDimensions = (
  height,
  width,
  parentHeight,
  parentWidth
) => ({
  relativeWidth: width && width / (parentWidth || 16),
  relativeHeight: height && height / (parentHeight || 6),
});

const DashboardElementContainer = styled.div`
  display: flex;
  flex-direction: ${({ relativeHeight }) =>
    relativeHeight ? 'row' : 'column'};
  flex-grow: ${({ relativeHeight, relativeWidth }) =>
    1 + (relativeHeight || relativeWidth)};
  height: ${({ relativeHeight }) =>
    relativeHeight ? `${relativeHeight * 100}%` : '100%'};
  ${({ minHeight }) => (minHeight ? `min-height: ${minHeight}px;` : '')}
  width: ${({ relativeWidth }) =>
    relativeWidth ? `${relativeWidth * 100}%` : '100%'};
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth}px;` : '')}
`;

const computeMinimumHeight = (component, children, height) => {
  if (component) {
    return component.minHeight;
  }
  return children.reduce((acc, child) => {
    const childMinHeight = computeMinimumHeight(
      child.component,
      child.children,
      child.height
    );
    if (height) {
      // If the parent has a height, the parent is a row thus
      // the children should not be taller than the parent
      return Math.max(acc, childMinHeight);
    }
    // If the parent has no height, the parent is a column thus
    // the children heights should be added
    return acc + childMinHeight;
  }, 0);
};

export const computeMinimumWidth = (rowOrCol) => {
  const isRow = rowOrCol.col === undefined;
  const childrenWidths = rowOrCol.children.map(
    (child) =>
      (child.component && (child.component.minWidth || 20)) ||
      computeMinimumWidth(child)
  );
  return isRow
    ? childrenWidths.reduce((acc, width) => acc + width || 20, 0)
    : Math.max(...childrenWidths);
};
function DashboardBlock({
  height,
  width,
  children,
  component,
  row,
  col,
  parentHeight,
  parentWidth,
  meta,
  formatters,
  accessor,
  makeTableData,
  loading,
  title,
  titleHelper,
  noDataMessage,
  getExportTitle,
  hideMenu,
}) {
  const { relativeHeight, relativeWidth } = computeRelativeDimensions(
    height,
    width,
    parentHeight,
    parentWidth
  );
  const minHeight = computeMinimumHeight(component, children, height);
  const minWidth =
    component?.minWidth || computeMinimumWidth({ children, col, row });
  const chartId = `chart-${row}-${col}`;
  return (
    <DashboardElementContainer
      key={`det-${row}-${col}`}
      relativeWidth={relativeWidth}
      relativeHeight={relativeHeight}
      minHeight={minHeight}
      minWidth={minWidth}
    >
      {component ? (
        <DashboardElement
          getExportTitle={(suffix) => getExportTitle()(suffix)}
          chartId={chartId}
          component={component}
          meta={meta}
          formatters={formatters}
          accessor={accessor}
          makeTableData={makeTableData}
          loading={loading}
          title={title}
          titleHelper={titleHelper}
          noDataMessage={noDataMessage}
          hideMenu={hideMenu}
          testid={`dashboard-header-${chartId}`}
        />
      ) : (
        children.map((element) => (
          <DashboardBlock
            getExportTitle={getExportTitle}
            key={`elem-${element.row}-${element.col}`}
            parentHeight={height}
            parentWidth={width}
            noDataMessage={noDataMessage}
            {...element}
            loading={loading}
          />
        ))
      )}
    </DashboardElementContainer>
  );
}

DashboardBlock.propTypes = {
  getExportTitle: PropTypes.func,
  accessor: PropTypes.func,
  children: PropTypes.arrayOf(PropTypes.shape(DashboardBlock.propTypes)),
  col: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  component: PropTypes.func,
  formatters: PropTypes.objectOf(PropTypes.func),
  height: PropTypes.number,
  loading: PropTypes.bool,
  makeTableData: PropTypes.func,
  meta: PropTypes.shape(),
  parentHeight: PropTypes.number,
  parentWidth: PropTypes.number,
  row: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  titleHelper: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  width: PropTypes.number,
  // Set to true to hide chart menu (export, data, fullscreen, etc.)
  hideMenu: PropTypes.bool,
  // Message to display when there is no data
  noDataMessage: PropTypes.string,
};
DashboardBlock.defaultProps = {
  getExportTitle: null,
  formatters: {},
  component: null,
  height: null,
  width: null,
  children: [],
  row: null,
  col: null,
  parentHeight: null,
  parentWidth: null,
  title: null,
  titleHelper: null,
  loading: false,
  meta: null,
  accessor: null,
  makeTableData: undefined,
  hideMenu: false,
  noDataMessage: undefined,
};

export default DashboardBlock;
