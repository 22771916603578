import React, { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon, Loader } from 'semantic-ui-react';

import canvasToImage from 'canvas-to-image';

import HelpTooltip from 'components/ui/HelpTooltip';
import HoverableIcon from 'components/ui/icon/HoverableIcon';
import logoImageSrc from 'components/ui/svg/logo.svg';

import { logEvent } from 'utils/analytics';
import commonPropTypes from 'utils/commonPropTypes';
import { capitalize } from 'utils/helpers';

import * as svars from 'assets/style/variables';

import { compileExportFilename, smallIconMargin } from './utils';

function ImageExportFooter() {
  return (
    <div
      style={{
        color: svars.fontColorLighter,
        fontSize: svars.fontSizeXSmall,
        textAlign: 'end',
        width: '100%',
        paddingTop: svars.spaceNormal,
        paddingRight: svars.spaceNormal,
        padding: `${svars.spaceNormal} ${svars.spaceNormal} 0 0`,
      }}
    >
      <span style={{ display: 'inline-flex' }}>
        Crafted by Better World
        <img
          style={{
            paddingLeft: svars.spaceSmall,
            height: `calc(1.2 * ${svars.fontSizeSmall})`,
          }}
          src={logoImageSrc}
          alt="null"
        />
      </span>
    </div>
  );
}

const gradientStrokeRegexp = /url\(#(.*)\)/i;
const updateStyleValues = {
  overflowY: 'show',
  overflowX: 'show',
};

const onHtml2CanvasClone =
  (exportId, footerId, initialStyleValues) => (clonedDocument) => {
    // Set footer opacity to 1
    const clonedFooter = clonedDocument.querySelector(`#${footerId}`);
    clonedFooter.style.opacity = 1;
    // Update cloned element style to handle overflow
    const clonedElement = clonedDocument.querySelector(`#${exportId}`);
    // Scroll targeted element to top to make sure we capture the complete component
    // If targeted element is scrolled, the top of it may not be captured
    clonedElement.scrollTop = 0;
    Object.entries(updateStyleValues).forEach(([attribute, value]) => {
      initialStyleValues[attribute] = clonedElement.style[attribute];
      clonedElement.style[attribute] = value;
    });
    // Fix gradient defs
    Array.from(
      clonedElement.getElementsByClassName('recharts-legend-item')
    ).forEach((legendElement) => {
      const [legendSvg] = legendElement.getElementsByTagName('svg');
      const legendPath = legendElement.querySelector('svg > path');
      if (legendSvg) {
        const legendStroke = legendPath.getAttribute('stroke');
        const firstChildStrokeOrFill =
          legendStroke !== 'none'
            ? legendStroke
            : legendPath.getAttribute('fill');
        if (firstChildStrokeOrFill && firstChildStrokeOrFill !== 'none') {
          // Check if stroke or fill is a gradient
          const gradientMatch = gradientStrokeRegexp.exec(
            firstChildStrokeOrFill
          );
          if (gradientMatch) {
            // If stroke or fill is a gradient, copy gradient definition to legend
            const gradientId = gradientMatch[1];
            legendSvg.appendChild(
              clonedDocument
                .getElementById(gradientId)
                .parentNode.cloneNode(true)
            );
          }
        }
      }
    });
  };

export const exportElementAsImage = (
  baseDocument,
  exportId,
  exportFileName,
  callback,
  windowHeight = window.innerHeight + window.outerHeight,
  scrollY = -window.scrollY
) => {
  const element = baseDocument.querySelector(`#${exportId}`);
  const footerId = `footer-${exportId}`;
  const footer = baseDocument.createElement('div');
  footer.id = footerId;
  footer.style.opacity = 0;
  element.appendChild(footer);
  const elementHeightWithoutFooter = element.clientHeight;
  ReactDOM.render(
    <ImageExportFooter style={{ opacity: 0 }} />,
    baseDocument.querySelector(`#${footerId}`)
  );

  const initialStyleValues = {};
  import('html2canvas').then((html2canvas) => {
    html2canvas
      .default(element, {
        // Scroll page back to top
        // If page is scrolled, part of the cloned element may be cut
        scrollY,
        allowTaint: true,
        // When `window.devicePixelRatio` (default value for scale) is equal to 1 (e.g. external screen of 21"+),
        // background colors are affected (especially sentiment cells)
        // Set it arbitrarily to 2 to avoid this effect
        scale: 2,
        // Add footer height manually as html2canvas does not handle it if transformed element
        // has an absolute height
        height: elementHeightWithoutFooter + footer.clientHeight + 2,
        ...(windowHeight ? { windowHeight } : {}),
        onclone: onHtml2CanvasClone(exportId, footerId, initialStyleValues),
      })
      .then((canvas) => {
        canvasToImage(canvas, {
          name:
            typeof exportFileName === 'function'
              ? exportFileName()
              : exportFileName,
          type: 'png',
          quality: 1,
        });
        // Remove footer as it was initially defined on the actual DOM element
        footer.remove();
        callback();
      });
  });
};

export function ExportAsImageIcon({
  onClick,
  disabled,
  hoverable,
  style,
  exportIconTestId,
}) {
  const extraProps = hoverable ? { as: HoverableIcon.Group } : {};
  const Component = hoverable ? HoverableIcon : Icon;
  return (
    <Component.Group
      {...extraProps}
      size="large"
      style={{ marginRight: smallIconMargin, ...style }}
      disabled={disabled}
      onClick={onClick}
      accent="true"
      data-testid={exportIconTestId}
    >
      <Icon name="file image outline" />
      <Icon
        corner
        name="arrow alternate circle down"
        style={{
          paddingLeft: smallIconMargin,
          paddingTop: smallIconMargin,
        }}
      />
    </Component.Group>
  );
}
ExportAsImageIcon.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  hoverable: PropTypes.bool,
  style: commonPropTypes.style,
  exportIconTestId: PropTypes.string,
};
ExportAsImageIcon.defaultProps = {
  disabled: false,
  hoverable: false,
  style: {},
  onClick: null,
  exportIconTestId: undefined,
};

function ExportAsImage({
  exportId,
  viewFacet,
  exportName,
  style,
  disabled,
  tooltipPosition,
  onClick,
  exportAsImageTestId,
}) {
  const [loading, setLoading] = useState(false);
  const onIconClick = useCallback(
    (event) => {
      if (!loading && onClick) {
        onClick(event);
      } else if (!(loading || disabled)) {
        setLoading(true);
        const exportFileName = compileExportFilename(
          (viewFacet && viewFacet.name) || null,
          exportName
        );
        exportElementAsImage(document, exportId, exportFileName, () => {
          setLoading(false);
          logEvent({
            category: 'Customer - Analyze',
            action: 'Export image',
            label: exportFileName,
          });
        });
      }
    },
    [exportId, loading, disabled, onClick, exportName, viewFacet]
  );
  return (
    <span
      data-testid={exportAsImageTestId}
      style={style}
      data-html2canvas-ignore="true"
    >
      {loading ? (
        <Loader inline="centered" active size="mini" />
      ) : (
        <HelpTooltip
          mountNode={document.querySelector('#popup-root')}
          compact
          position={tooltipPosition}
          mouseEnterDelay={300}
          mouseLeaveDelay={50}
          help={capitalize(t`export-as-image`)}
          hoverable={false}
          trigger={
            <span>
              <ExportAsImageIcon
                hoverable
                disabled={disabled}
                onClick={onIconClick}
              />
            </span>
          }
        />
      )}
    </span>
  );
}

ExportAsImage.propTypes = {
  viewFacet: commonPropTypes.viewFacet,
  // Id of the component to export
  exportId: PropTypes.string,
  disabled: PropTypes.bool,
  exportName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  style: commonPropTypes.style,
  tooltipPosition: PropTypes.string,
  // Override click behaviour by setting `onClick`
  onClick: PropTypes.func,
  exportAsImageTestId: PropTypes.string,
};

ExportAsImage.defaultProps = {
  viewFacet: null,
  exportId: 'exportChartBase',
  exportName: null,
  disabled: false,
  style: {},
  tooltipPosition: 'top center',
  onClick: null,
  exportAsImageTestId: undefined,
};

export default React.memo(
  connect((state) => ({ viewFacet: state.view.viewFacet }))(ExportAsImage)
);
