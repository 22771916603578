import reactStringReplace from 'react-string-replace';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HelpTooltip from 'components/ui/HelpTooltip';

import { removeHtmlTags } from 'utils/helpers';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';
import { fontColorLighter } from 'assets/style/variables';

export const LightText = styled.i`
  color: ${fontColorLighter};
`;

export const LimitedTextCell = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const anonymizationTypeIconMap = {
  ADDRESS: { icon: '🏠', label: t`address` },
  DATE_TIME: { icon: '📅', label: t`date-time` },
  EMAIL_ADDRESS: { icon: '📧', label: t`email-address` },
  PERSON: { icon: '👤', label: t`person` },
  PHONE_NUMBER: { icon: '📞', label: t`phone-number` },
  LOCALISATION: { icon: '🏠', label: t`localisation` },
  CONTRACT: { icon: '📄', label: t`contract` },
  REFERENCE: { icon: '🆔', label: t`reference` },
  BP: { icon: '🆔', label: t`identifier` },
  INFO_BANK: { icon: '🏦', label: t`bank-info` },
  EMAIL: { icon: '📧', label: t`email` },
  PDL: { icon: '🆔', label: t`identifier` },
  FACTURATION_ACCOUNT: { icon: '🆔', label: t`facturation-account` },
  PCE: { icon: '🆔', label: t`identifier` },
};

export const displayAnonymizedElements = (text) =>
  text
    ? reactStringReplace(
        text,
        /(?:<|__)([A-Z](?:[A-Z_]+)?[A-Z])(?:>|__)/g,
        (match, i) => {
          const element = anonymizationTypeIconMap[match];
          return (
            <HelpTooltip
              key={i}
              help={
                <>
                  <Trans render={capitalizedTranslation} id={element?.label} />{' '}
                  (
                  <Trans id="anonymized" />)
                </>
              }
              baseColor
              compact
              basic
              trigger={
                <span style={{ margin: `0 ${svars.spaceSmall}` }}>
                  {element?.icon}
                </span>
              }
            />
          );
        }
      )
    : text;

const BaseLimitedRichTextCell = styled(LimitedTextCell)`
  max-width: 100%;
  & * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export function LimitedRichTextCell({ value, ...props }) {
  return (
    <BaseLimitedRichTextCell
      {...props}
      dangerouslySetInnerHTML={{ __html: removeHtmlTags(value) }}
    />
  );
}
LimitedRichTextCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export default LightText;
