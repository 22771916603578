import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { formElementSelectorFactory } from 'selectors/campaign';
import { usersItemsSelectorFactory } from 'selectors/entities';
import {
  annotateFeedback,
  annotateLoadingSelector,
} from 'store/monitor/monitorSearchSlice';

import Link from 'components/ui/Link';
import { AnalyticsAwareHoverableIconButtonWithTooltip } from 'components/ui/icon/HoverableIcon';
import { ResettableTextInput } from 'components/ui/inputs/TextInput';

import * as svars from 'assets/style/variables';

import { StyledDropdown } from '../inputs/Dropdown';
import { LabelCell, UserLabelCell } from '../table/cells/ReactTableCell';

const CellContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  width: 100%;
`;
function EditableAnnotationCell({
  formId,
  feedbackId,
  isText,
  isUser,
  value: savedValue,
  color: savedColor,
}) {
  const [editing, setEditing] = useState(false);
  const [formValue, setFormValue] = useState(
    isText && savedValue !== '-' ? savedValue : null
  );
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const {
    formElement: { placeholder, label, mutable, users: annotableUsers },
    tagSet: tagSetItems,
  } = useSelector(
    formElementSelectorFactory(campaignId, 'annotation_form', formId, true)
  );
  const annotableUserIds = annotableUsers?.map(({ id }) => id);
  const users = useSelector(usersItemsSelectorFactory(false))?.filter(
    ({ value }) => annotableUserIds?.includes(value)
  );
  const annotateLoading = useSelector(annotateLoadingSelector);
  const onChange = useCallback((e, { value }) => setFormValue(value), []);
  const onTextReset = useCallback(() => setFormValue(''), [onChange]);
  const onSave = useCallback(
    () =>
      dispatch(
        annotateFeedback({
          campaignId,
          feedbackId,
          formId,
          value: formValue,
        })
      ).then(() => setEditing(false)),
    [campaignId, feedbackId, formId, formValue]
  );
  const commonProps = {
    style: { width: '100%' },
    onChange,
    placeholder: placeholder || label,
  };
  const LabelCellComponent = isUser ? UserLabelCell : LabelCell;
  if (savedValue && savedValue !== '-' && !editing) {
    return (
      <CellContainer>
        <LabelCellComponent value={savedValue} color={savedColor} />
        {mutable ? (
          <AnalyticsAwareHoverableIconButtonWithTooltip
            onClick={() => setEditing(true)}
            style={{ margin: `0 ${svars.spaceMedium}` }}
            help={t`edit-annotation`}
            name="edit"
            gaCategory="Monitoring - search"
            gaAction="Edit feedback annotation"
          />
        ) : null}
      </CellContainer>
    );
  }
  return (
    // use min height to ensure enough size for all input types
    <CellContainer>
      {!editing ? (
        <Link onClick={() => setEditing(true)} base primary>
          <Trans id="empty-click-to-edit" />
        </Link>
      ) : (
        <>
          {(isText && (
            <ResettableTextInput
              baseIconName={null}
              onReset={onTextReset}
              {...commonProps}
              value={formValue || ''}
            />
          )) ||
            (isUser && (
              <StyledDropdown
                fluid
                search
                value={formValue}
                selection
                clearable
                options={users}
                {...commonProps}
                disabled={annotateLoading}
                noResultsMessage={i18n._(t`no-annotable-users`)}
              />
            )) || (
              <StyledDropdown
                fluid
                search
                value={formValue}
                selection
                clearable
                options={tagSetItems}
                {...commonProps}
                disabled={annotateLoading}
                noResultsMessage={i18n._(t`no-annotable-tags`)}
              />
            )}
          {}
          <AnalyticsAwareHoverableIconButtonWithTooltip
            onClick={onSave}
            style={{ margin: `0 ${svars.spaceMedium}` }}
            help={t`save-annotation`}
            icon="check"
            gaCategory="Monitoring - search"
            gaAction="Annotate feedback"
            large="true"
            success="true"
            name="check"
            loading={annotateLoading}
            disabled={!formValue}
          />
        </>
      )}
    </CellContainer>
  );
}
EditableAnnotationCell.propTypes = {
  formId: PropTypes.string.isRequired,
  feedbackId: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  color: PropTypes.string,
  // Whether this is a text annotation or not
  isText: PropTypes.bool,
  // Whether this is a user annotation or not
  isUser: PropTypes.bool,
};
EditableAnnotationCell.defaultProps = {
  value: null,
  color: null,
  isText: false,
  isUser: false,
};

export const AnnotationCellFactory = ({ isText = false, isUser = false }) => {
  function AnnotationCell({ formId, feedbackId, value, color }) {
    return (
      <EditableAnnotationCell
        formId={formId}
        feedbackId={feedbackId}
        isText={isText}
        isUser={isUser}
        value={value}
        color={color}
      />
    );
  }
  AnnotationCell.propTypes = {
    formId: PropTypes.string.isRequired,
    feedbackId: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]),
    color: PropTypes.string,
  };
  AnnotationCell.defaultProps = { value: null, color: null };
  return AnnotationCell;
};

export const AnnotationTagCell = AnnotationCellFactory({});
export const AnnotationTextCell = AnnotationCellFactory({ isText: true });
export const AnnotationUserCell = AnnotationCellFactory({ isUser: true });
