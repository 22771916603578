import { useCallback } from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';

import * as svars from 'assets/style/variables';

import StyledSegment from '../Segment';
import DragHandle from '../icon/DragHandle';

export const DraggableSegment = styled(StyledSegment)`
  &&& {
    ${({ clickable }) =>
      clickable
        ? css`
            ${svars.hoverClickableCss}
            ${svars.activeClickableCss}
          `
        : ''}
    display: inline-flex;
    width: 100%;
    min-height: 45px;
    ${({ padded }) =>
      padded ? `padding: ${svars.spaceMedium};` : 'padding: 0;'}
    margin: ${svars.spaceSmall} 0;
    align-items: center;
    background: ${(props) =>
      props.archived ? svars.colorDangerLightest : svars.colorWhite};
    ${({ clickable }) => (clickable ? svars.hoverClickableCss : '')}
    ${svars.activeClickableCss}
  }
`;

const DraggableContainer = styled.div`
  overflow: auto;
  padding: ${svars.spaceSmall};
  width: 100%;
  pointer-events: auto;
  transition: ${svars.transitionBase};
  background: ${({ dragging }) =>
    dragging ? `${svars.colorLighterGrey}` : 'transparent'};
  ${({ clickable }) =>
    clickable
      ? `
    cursor: pointer;
    &:hover {
      & ${DraggableSegment} {
        background: ${svars.accentColorTransparent};
        box-shadow: ${svars.selectedBoxShadow};
      }
    `
      : ''}
`;

const generateId = () => `id${Math.random().toString(16).slice(2)}`;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const renderDraggable = (
  renderElement,
  item,
  index,
  disabled,
  onRowClick,
  active,
  padded
) => {
  function DraggableContent(
    { innerRef, draggableProps, dragHandleProps },
    { isDragging }
  ) {
    return (
      <div ref={innerRef} {...((!disabled && draggableProps) || {})}>
        <DraggableSegment
          onClick={onRowClick ? () => onRowClick(item) : null}
          padded={padded}
          dragging={isDragging ? 'true' : null}
          clickable={onRowClick ? 'true' : null}
          active={active ? 'true' : null}
          archived={item?.archived ? 'true' : null}
        >
          <DragHandle {...dragHandleProps} disabled={disabled} />

          {renderElement(item, index)}
        </DraggableSegment>
      </div>
    );
  }
  DraggableContent.propTypes = {
    innerRef: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    draggableProps: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    dragHandleProps: PropTypes.object.isRequired,
  };
  return DraggableContent;
};

function DragAndDropList({
  elements,
  onChange,
  onRowClick,
  getElementId,
  renderElement,
  isItemDisabled,
  selected,
  padded,
  style,
}) {
  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        // Dropped outside the list
        return;
      }
      onChange(
        reorder(elements, result.source.index, result.destination.index)
      );
    },
    [elements, onChange]
  );
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <DraggableContainer
            {...provided.droppableProps}
            ref={provided.innerRef}
            dragging={snapshot.isDraggingOver ? 'true' : null}
            style={style}
          >
            {elements.map((item, index) => {
              const elementId = getElementId(item);
              const disabled = isItemDisabled?.(item) || false;
              return (
                <Draggable
                  key={elementId}
                  draggableId={`${elementId || generateId()}`}
                  index={index}
                  disabled={disabled}
                >
                  {renderDraggable(
                    renderElement,
                    item,
                    index,
                    disabled,
                    onRowClick,
                    selected && getElementId(selected) === elementId,
                    padded
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </DraggableContainer>
        )}
      </Droppable>
    </DragDropContext>
  );
}
DragAndDropList.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])),
        PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])),
      ])
    )
  ).isRequired,
  // If not set, drag and drop is deactivated
  onChange: PropTypes.func,
  // If set, the row is clickable
  onRowClick: PropTypes.func,
  // Get the id of the element, used to identify and compare elements
  getElementId: PropTypes.func,
  renderElement: PropTypes.func.isRequired,
  isItemDisabled: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  selected: PropTypes.objectOf(PropTypes.any),
  padded: PropTypes.bool,
  style: PropTypes.shape(),
};
DragAndDropList.defaultProps = {
  onChange: null,
  onRowClick: null,
  isItemDisabled: null,
  getElementId: (element) => element?.id,
  selected: null,
  padded: false,
  style: {},
};

export default DragAndDropList;
