import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon, Label, Button as SemanticButton } from 'semantic-ui-react';
import styled from 'styled-components';

import { transparentize } from 'polished';

import HelpTooltip from 'components/ui/HelpTooltip';

import { logEvent } from 'utils/analytics';
import commonPropTypes from 'utils/commonPropTypes';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

export const Button = styled(SemanticButton)`
  &&&& {
    margin: auto 0;
    color: ${svars.accentColor};
    font-size: ${svars.fontSizeLarge};
    min-width: ${(props) => (props.fitted ? null : svars.buttonMinWidth)};
    max-height: ${svars.buttonMaxHeight};
    min-height: ${({ fitted }) => (fitted ? null : svars.buttonMinHeight)};
    transition: ${svars.transitionBase};
    border: ${svars.inputBorderWidth} solid transparent;

    ${({ nowrap }) =>
      (nowrap &&
        `
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
    `) ||
      ''}

    & i.icon {
      background-color: transparent;
    }

    &:first-letter {
      text-transform: uppercase;
    }
  }
`;

const makeButtonStyles = (
  color,
  colorOnHover,
  colorOnDisabled,
  fontColor = svars.colorWhite,
  fontColorOnHover = svars.colorWhiteHover,
  isButtonTransparent = false
) =>
  styled(Button)`
    &&&& {
      background-size: 300% 100%;
      font-weight: ${svars.fontWeightBase};
      cursor: ${(props) => (props.disabled && 'default') || 'pointer'};
      color: ${(props) => (props.active && fontColorOnHover) || fontColor};
      background-color: ${(props) =>
        (props.disabled && !isButtonTransparent && colorOnDisabled) ||
        'transparent'};
      background-image: ${(props) =>
        ((isButtonTransparent || props.disabled) && 'none') ||
        `linear-gradient(to right, ${color}, ${color})`};
      border-color: ${isButtonTransparent
        ? 'transparent'
        : (props) =>
            (props.disabled && colorOnDisabled) ||
            (props.active && colorOnHover) ||
            color};
      transition: all 0.2s ease-in-out;
      // Potential new background color to address accessibility issues in Storybook."
      /* background-color: ${(props) =>
        ((isButtonTransparent || props.disabled) && 'transparent') || color};
      transition: all 0.3s ease-in-out;  */
      border: 1px solid transparent;
      ${(isButtonTransparent && `box-shadow: none !important;`) || ''}

      & label {
        border-color: ${isButtonTransparent
          ? 'transparent'
          : (props) =>
              (props.disabled && colorOnDisabled) ||
              (props.active && colorOnHover) ||
              color};
      }

      :hover,
      :active {
        background-position: 70% 0;

        color: ${fontColorOnHover};
        ${(props) =>
          (props.active && `background-color: ${colorOnHover};`) ||
          (props.disabled && `background-color: ${colorOnDisabled};`) ||
          `background-image: linear-gradient(
          to right,
          ${colorOnHover},
          ${colorOnHover},
          ${color}

          );`}
        border: 1px solid transparent;
      }
    }
  `;

const makeButtonSecondaryStyles = (color) =>
  styled(Button)`
    &&&& {
      font-weight: ${svars.fontWeightSemiBold};
      color: ${(props) => (props.active && svars.colorWhite) || color};
      background-image: linear-gradient(
        to right,
        ${(props) => (props.active && color) || svars.accentColorLighter},
        ${svars.accentColorLighter}
      ) !important;
      transition: all 0.2s ease-in-out;
      background-size: 300% 100%;
      border: 1px solid ${transparentize(0.5, color)};

      & div.label {
        border: 1px solid ${transparentize(0.5, color)} !important;
        border-left: none;
      }

      &:hover {
        border-color: ${color};
        background-position: 70% 0;
        background-image: linear-gradient(
          to right,
          ${svars.accentColorLighter},
          ${svars.accentColorMedium},
          ${svars.accentColorLightest}
        ) !important;
        box-shadow: ${svars.inheritedSelectedBoxShadow(
          transparentize(0.2, svars.accentColorLighter)
        )};

        & div.label {
          border-color: ${color};
        }
      }
    }
  `;

export const ButtonPrimary = makeButtonStyles(
  svars.colorPrimary,
  svars.colorPrimaryHover,
  svars.colorPrimaryDisabled
);

export const ButtonPrimaryHuge = styled(ButtonPrimary)`
  &&&& {
    height: 3.5rem;
    font-size: 1.2rem;
  }
`;

export const ButtonSuccess = makeButtonStyles(
  svars.colorSuccess,
  svars.colorSuccessHover,
  svars.colorSuccessDisabled
);
export const ButtonWarning = makeButtonStyles(
  svars.colorWarning,
  svars.colorWarningHover,
  svars.colorWarningDisabled
);
export const ButtonDanger = makeButtonStyles(
  svars.colorDanger,
  svars.colorDangerHover,
  svars.colorDangerDisabled
);
export const ButtonAccent = makeButtonStyles(
  svars.accentColor,
  svars.accentColorHover,
  svars.accentColorDisabled
);

export const ButtonSecondary = makeButtonSecondaryStyles(svars.colorPrimary);
export const ButtonSecondarySuccess = makeButtonSecondaryStyles(
  svars.colorSuccess
);

export const ButtonSecondaryWarning = makeButtonSecondaryStyles(
  svars.colorWarning
);
export const ButtonSecondaryAccent = makeButtonSecondaryStyles(
  svars.accentColor
);

export const ButtonTransparent = makeButtonStyles(
  'transparent',
  'transparent',
  svars.colorPrimaryDisabled,
  svars.colorPrimary,
  svars.accentColor,
  true
);
export const ButtonTransparentDanger = makeButtonStyles(
  'transparent',
  'transparent',
  svars.colorPrimaryDisabled,
  svars.colorGrey,
  svars.colorDanger,
  true
);
export const ButtonTransparentSuccess = makeButtonStyles(
  'transparent',
  'transparent',
  svars.colorPrimaryDisabled,
  svars.colorGrey,
  svars.colorSuccess,
  true
);
export const ButtonTransparentWarning = makeButtonStyles(
  'transparent',
  'transparent',
  svars.colorPrimaryDisabled,
  svars.colorGrey,
  svars.colorWarning,
  true
);

export const ButtonTransparentAccent = makeButtonStyles(
  'transparent',
  'transparent',
  svars.accentColorDisabled,
  svars.colorGrey,
  svars.accentColor,
  true
);

function AnalyticsAwareButton({
  inputComponent,
  gaCategory,
  gaAction,
  gaLabel,
  gaNonInteraction,
  disabledHelp,
  disabled,
  ...props
}) {
  const ButtonComponent = inputComponent;
  const button = (
    <ButtonComponent
      disabled={disabled}
      {...props}
      onClick={(...params) => {
        if (gaCategory !== undefined || gaAction !== undefined) {
          const gaProps = {
            category: gaCategory,
            action: gaAction,
            label: gaLabel,
            nonInteraction: gaNonInteraction,
          };
          logEvent(gaProps);
        }
        if (props.onClick) props.onClick(...params);
      }}
    />
  );
  return disabled && disabledHelp ? (
    <HelpTooltip
      compact
      help={[disabledHelp]}
      trigger={<span>{button}</span>}
    />
  ) : (
    button
  );
}

AnalyticsAwareButton.propTypes = {
  ...SemanticButton.propTypes,
  inputComponent: commonPropTypes.component,
  onClick: PropTypes.func,
  gaCategory: PropTypes.string,
  gaAction: PropTypes.string,
  gaLabel: PropTypes.string,
  gaNonInteraction: PropTypes.bool,
};

AnalyticsAwareButton.defaultProps = {
  inputComponent: ButtonPrimary,
  gaCategory: undefined,
  gaAction: undefined,
  gaLabel: undefined,
  gaNonInteraction: false,
  onClick: undefined,
};

const DeleteLabel = styled(Label)`
  &&&& {
    border-radius: 0 ${svars.ctaBorderRadius} ${svars.ctaBorderRadius} 0;
    color: ${svars.colorDangerLighter} !important;
    border-left: none;
    padding: ${svars.spaceNormal};
    margin: 0;
    i {
      background: transparent;
      i {
        text-shadow: none;
      }
    }
    ::before {
      display: none;
    }

    :hover {
      background-color: ${svars.colorDangerLighter} !important;
      i {
        color: ${svars.colorDanger};
      }
    }
  }
`;

const LabelContainer = styled.span`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`;
function SecondaryButtonWithDeleteLabel({
  onClick,
  onLabelClick,
  loading,
  showLabel,
  labelHelp,
  ButtonComponent,
  ...otherProps
}) {
  return (
    <ButtonComponent
      onClick={onClick}
      disabled={loading}
      label={
        showLabel ? (
          <DeleteLabel disabled={loading} onClick={onLabelClick}>
            <HelpTooltip
              compact
              disabled={loading}
              trigger={
                <LabelContainer>
                  <Icon.Group>
                    <Icon name="filter" />
                    <Icon corner name="delete" />
                  </Icon.Group>
                </LabelContainer>
              }
              position="bottom right"
              mouseEnterDelay={200}
              help={<Trans id={labelHelp} />}
            />
          </DeleteLabel>
        ) : null
      }
      icon={showLabel ? null : 'filter'}
      content={<Trans render={capitalizedTranslation} id="filters" />}
      style={{
        flexShrink: 0,
        // Set to 1 to appear above review pane dimmer so the button
        // stays clickable when review pane is open
        zIndex: 1,
      }}
      {...otherProps}
    />
  );
}
SecondaryButtonWithDeleteLabel.propTypes = {
  onClick: PropTypes.func.isRequired,
  onLabelClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  showLabel: PropTypes.bool.isRequired,
  labelHelp: PropTypes.string,
  ButtonComponent: commonPropTypes.component,
};
SecondaryButtonWithDeleteLabel.defaultProps = {
  labelHelp: t`clear-all-filters`,
  ButtonComponent: ButtonSecondaryAccent,
};

export { AnalyticsAwareButton, SecondaryButtonWithDeleteLabel };
