import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Accordion, Icon, Placeholder } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  conceptColorFormatterSelector,
  entityLabelFormatterSelector,
  tagColorFormatterSelector,
} from 'selectors/entities';

import { displayAnonymizedElements } from 'components/ui/Text';

import commonPropTypes from 'utils/commonPropTypes';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

const CellContainer = styled.div``;

const ChunkContainer = styled(Accordion)`
  &&& {
    cursor: ${({ disabled }) => (disabled ? 'arrow' : 'pointer')};
    padding: ${svars.spaceNormal} ${svars.spaceNormalLarge};
    text-align: justify;
    transition: ${svars.transitionBase};
    background: ${({ highlight }) =>
      (highlight && svars.accentColorTransparent) || 'inherit'};
    box-shadow: ${({ active, disabled }) =>
      active && !disabled ? svars.selectedBoxShadow : 'none'};
    border-left: 3px solid
      ${({ active }) => (active ? svars.colorPrimary : 'transparent')};

    &:hover {
      border-color: ${({ disabled }) =>
        !disabled ? svars.colorPrimary : svars.colorLightGrey};
    }
  }
`;
const ChunkInfoItems = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: ${svars.fontSizeSmaller};
`;
const ChunkInfoItem = styled.span`
  margin: ${svars.spaceSmall} ${svars.spaceNormal};
  padding-bottom: ${svars.spaceXSmall};
  margin-top: ${svars.spaceXSmall};
  margin-bottom: ${svars.spaceSmall};
  border-bottom: 2px solid ${({ color }) => color || 'transparent'};
  white-space: nowrap !important;
`;

const ChunkInfoHeader = styled.div`
  font-weight: ${svars.fontWeightMedium};
  font-size: ${svars.fontSizeSmaller};
`;

const ChunkInfoSummaryContainer = styled.button`
  float: right;
  padding: 0;
  margin-top: ${svars.spaceXSmall};
  margin-left: ${svars.spaceSmall};
  font-size: ${svars.fontSizeSmaller};
  width: fit-content;
  align-self: flex-end;
  color: ${svars.colorPrimary};
  background: ${svars.accentColorLighter};
  border-color: transparent;
  border-radius: ${svars.ctaBorderRadius};
  white-space: nowrap;
  transition: ${svars.transitionBase};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;
const ChunkInfoSummaryItem = styled.span`
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  font-weight: ${svars.fontWeightMedium};
`;

const FigureContainer = styled.span`
  margin-right: 5px;
`;
function ChunkInfoSummary({ nClassifications, nTags, disabled }) {
  return (
    <ChunkInfoSummaryContainer disabled={disabled}>
      <ChunkInfoSummaryItem disabled={nClassifications <= 0}>
        <Icon style={{ marginRight: 0 }} name="fork" />
        <FigureContainer>{nClassifications}</FigureContainer>
      </ChunkInfoSummaryItem>
      <ChunkInfoSummaryItem disabled={nTags <= 0}>
        <Icon name="tags" />
        <FigureContainer>{nTags}</FigureContainer>
      </ChunkInfoSummaryItem>

      <Icon style={{ marginRight: 0 }} name="caret down" />
    </ChunkInfoSummaryContainer>
  );
}

ChunkInfoSummary.propTypes = {
  nClassifications: PropTypes.number,
  nTags: PropTypes.number,
  disabled: PropTypes.bool,
};
ChunkInfoSummary.defaultProps = {
  nClassifications: 0,
  nTags: 0,
  disabled: false,
};

function ChunkInfo({
  label,
  iconName,
  items,
  itemLabelFormatter,
  itemColorFormatter,
  style,
}) {
  if (!items?.length) return null;
  return (
    <div style={style}>
      <ChunkInfoHeader>
        <Icon name={iconName} />
        <Trans render={capitalizedTranslation} id={label} />
      </ChunkInfoHeader>
      <ChunkInfoItems>
        {items.map((item) => (
          <ChunkInfoItem
            color={itemColorFormatter(item)}
            // Quick fix to handle concept case which does not have an id
            // TODO: remove alternative when concept has db id
            key={item?.db_concept?.id || item?.form_element?.id || item?.id}
          >
            {itemLabelFormatter(item)}
          </ChunkInfoItem>
        ))}
      </ChunkInfoItems>
    </div>
  );
}

ChunkInfo.propTypes = {
  label: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  itemLabelFormatter: PropTypes.func.isRequired,
  itemColorFormatter: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
};
ChunkInfo.defaultProps = {
  items: [],
  style: {},
};

export function ReviewChunksLoadingCell() {
  return (
    <CellContainer>
      {[1, 2].map((item) => (
        <ChunkContainer key={item}>
          <Placeholder
            style={{
              width: '100%',
              marginRight: svars.spaceLarge,
            }}
          >
            <Placeholder.Line style={{ height: '5px' }} />
            <Placeholder.Line style={{ height: '5px' }} />
            <Placeholder.Line style={{ height: '5px' }} />
          </Placeholder>
        </ChunkContainer>
      ))}
    </CellContainer>
  );
}

const reviewChunksCellFactory = (highlightIndex, TextComponent = null) => {
  function ReviewChunksCell({
    value,
    classifications,
    chunksFormElementValues,
  }) {
    const entityLabelFormatter = useSelector(entityLabelFormatterSelector);
    const [toggled, setToggled] = useState({});
    const tagColorFormatter = useSelector(tagColorFormatterSelector);
    const conceptColorFormatter = useSelector(conceptColorFormatterSelector);
    const addToggled = (index) => () => {
      const newToggled = { ...toggled };
      newToggled[index] = !newToggled[index];
      setToggled(newToggled);
    };
    return (
      <CellContainer>
        {value?.map((item, chunkIndex) => {
          const nClassifications = classifications?.[chunkIndex]?.length;
          const nTags = chunksFormElementValues?.[chunkIndex]?.length;
          const disabled = !((nClassifications || 0) + (nTags || 0));

          return (
            <ChunkContainer
              highlight={highlightIndex === chunkIndex ? '1' : null}
              active={toggled[chunkIndex] ? '1' : null}
              key={item}
              onClick={!disabled ? addToggled(chunkIndex) : null}
              disabled={disabled}
            >
              <Accordion.Title
                style={{
                  padding: 0,
                  display: 'inline-block',
                  width: '100%',
                  cursor: 'inherit',
                }}
              >
                <span style={{ flexGrow: 1 }}>
                  {TextComponent ? (
                    <TextComponent item={item} />
                  ) : (
                    displayAnonymizedElements(item)
                  )}
                </span>
                <ChunkInfoSummary
                  disabled={disabled}
                  nClassifications={nClassifications}
                  nTags={nTags}
                />
              </Accordion.Title>
              <Accordion.Content
                active={!!toggled[chunkIndex]}
                style={{ marginLeft: svars.spaceNormal }}
              >
                <ChunkInfo
                  label={t`concepts`}
                  iconName="fork"
                  items={classifications?.[chunkIndex]}
                  itemLabelFormatter={(rowItem) =>
                    entityLabelFormatter('concept', rowItem?.db_concept.id)
                  }
                  itemColorFormatter={(rowItem) =>
                    conceptColorFormatter(rowItem?.db_concept.id)
                  }
                />
                <ChunkInfo
                  style={{ marginTop: svars.spaceNormal }}
                  label={t`tags`}
                  iconName="tags"
                  items={chunksFormElementValues?.[chunkIndex]}
                  itemLabelFormatter={(rowItem) =>
                    entityLabelFormatter('tag', rowItem?.tag?.id)
                  }
                  itemColorFormatter={(rowItem) =>
                    tagColorFormatter(rowItem?.tag?.id)
                  }
                />
              </Accordion.Content>
            </ChunkContainer>
          );
        })}
      </CellContainer>
    );
  }
  ReviewChunksCell.propTypes = {
    value: PropTypes.arrayOf(PropTypes.string),
    classifications: commonPropTypes.itemsClassifications,
    chunksFormElementValues: PropTypes.arrayOf(
      PropTypes.arrayOf(commonPropTypes.formElementValue)
    ),
  };
  ReviewChunksCell.defaultProps = {
    value: [],
    classifications: [],
    chunksFormElementValues: [],
  };
  return ReviewChunksCell;
};

export default reviewChunksCellFactory;
