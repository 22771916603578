import { useMemo } from 'react';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon, Modal } from 'semantic-ui-react';

import { ButtonTransparentAccent } from 'components/ui/button';
import { PrimaryTabButton } from 'components/ui/button/TabButton';
import ReactTable from 'components/ui/table/ReactTableUi';

import * as svars from 'assets/style/variables';

import { ButtonLineLayout } from '../layout/Page';

function DataModal({
  title,
  open,
  data,
  makeTableData,
  meta,
  onClose,
  exportAsData,
}) {
  const {
    data: tableData,
    columns,
    defaultSorted,
  } = useMemo(() => makeTableData({ data, meta }), [data]);
  if (!data) {
    return null;
  }
  return (
    <Modal closeIcon open={open} onClose={onClose}>
      <Modal.Header content={typeof title === 'function' ? title() : title} />
      <Modal.Content
        style={{
          padding: `${svars.spaceMedium} ${svars.spaceMediumLarge}`,
          maxHeight: '75vh',
          margin: 'auto',
          overflowY: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ButtonLineLayout style={{ marginBottom: svars.spaceNormal }}>
          <PrimaryTabButton
            onClick={exportAsData}
            icon={
              <Icon
                style={{ width: '2rem' }}
                size="large"
                name="file excel outline"
              />
            }
            labelPosition="right"
            content={<Trans id="export" />}
          />
        </ButtonLineLayout>
        <ReactTable
          data={tableData}
          columns={columns}
          defaultSorted={defaultSorted}
          noDataText={t`no-data`}
        />
      </Modal.Content>
      <Modal.Actions>
        <ButtonTransparentAccent onClick={onClose} content={t`close`} />
      </Modal.Actions>
    </Modal>
  );
}

DataModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  makeTableData: PropTypes.func.isRequired,
  meta: PropTypes.shape().isRequired,
  open: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClose: PropTypes.func.isRequired,
  exportAsData: PropTypes.func.isRequired,
};
DataModal.defaultProps = {
  open: false,
  title: null,
};

export default DataModal;
