

import PropTypes from 'prop-types';
import { Checkbox as BaseCheckBox } from 'semantic-ui-react';
import styled from 'styled-components';

import commonPropTypes from 'utils/commonPropTypes';
import withClickAnalytics from 'utils/withAnalytics';

import * as svars from 'assets/style/variables';

import { LightHeader } from '../Header';

export const LightCheckbox = styled(BaseCheckBox)`
  &&&&&&& {
    & label {
      color: ${svars.fontColorBase} !important;
      font-weight: normal;
      float: left;
    }
  }
`;

export const Checkbox = styled(BaseCheckBox)`
  &&&&&&& {
    & label {
      color: ${svars.fontColorBase} !important;

      &::after {
        color: ${svars.accentColor};
      }
    }
  }
`;

const AnalyticsAwareCheckbox = withClickAnalytics(Checkbox, 'onChange');

AnalyticsAwareCheckbox.propTypes = {
  ...BaseCheckBox.propTypes,
  as: commonPropTypes.component,
  onChange: PropTypes.func,
  gaCategory: PropTypes.string,
  gaAction: PropTypes.string,
  gaLabel: PropTypes.string,
  gaNonInteraction: PropTypes.bool,
};

AnalyticsAwareCheckbox.defaultProps = {
  ...BaseCheckBox.defaultProps,
  as: Checkbox,
  gaCategory: undefined,
  gaAction: undefined,
  gaLabel: undefined,
  gaNonInteraction: false,
};

const ToggleContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${svars.spaceMedium};
  ${svars.hoverClickableCss}
`;

function Toggle({ label, checked, onChange, toggleTestId, ...props }) {
  return (
    <ToggleContainer onClick={() => onChange(!checked)}>
      <LightHeader nomargin="true">{label}</LightHeader>
      <LightCheckbox
        data-testid={toggleTestId}
        toggle
        checked={checked}
        {...props}
      />
    </ToggleContainer>
  );
}

Toggle.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  toggleTestId: PropTypes.string,
};

Toggle.defaultProps = {
  toggleTestId: undefined,
};


export { Toggle, AnalyticsAwareCheckbox };
